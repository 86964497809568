<template>
  <div class="permission backMenu">
    <div class="role">
      <div class="input-box">
        <div class="sch"><span>*</span>角色名称</div>
        <el-input
          placeholder="请输入角色名称"
          v-model="name"
          :disabled="$route.query.type == 2"
          maxlength="16"
          @blur="blank()"
        >
        </el-input>
      </div>
      <div class="input-box">
        <div class="sch">角色描述</div>
        <el-input
          placeholder="请输入角色描述"
          v-model="describe"
          :disabled="$route.query.type == 2"
          maxlength="50"
        >
        </el-input>
      </div>
      <div class="input-box" v-if="$route.query.type != 2">
        <div class="sch">参考角色</div>
        <template>
          <el-select
            v-model="reference"
            placeholder="请选择"
            :disabled="$route.query.type == 2"
            @change="station(reference)"
            clearable
          >
            <el-option
              v-for="item in optionsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </div>
      <div class="input-box" v-if="$route.query.type == 2"></div>
    </div>
    <div class="permissionList">
      <div class="sch install"><span>*</span>权限设置</div>
      <div
        class="input-box"
        v-if="$route.query.type == 1 || $route.query.type == 3"
      >
        <el-cascader
          v-model="seachValue"
          :options="seachOption"
          filterable
          :props="{ checkStrictly: true }"
          @change="inquireBtn()"
          clearable
        ></el-cascader>
        <!-- <el-input placeholder="请输入权限名称" v-model="input1"></el-input> -->
        <el-button class="el-button" @click="inquireBtn()">查询</el-button>
      </div>
      <div class="table">
        <div class="table-head">
          <div class="first">一级菜单</div>
          <div class="second">二级菜单</div>
          <div class="authority">菜单权限</div>
        </div>
        <div class="table-body">
          <div
            class="table-row"
            v-for="(item, index) in allPowerList"
            :key="index"
          >
            <div class="first">
              <el-checkbox
                v-model="item.check"
                :border="item.back"
                :disabled="$route.query.type == 2"
                @change="firstChange(item, index)"
                >{{ item.name }}</el-checkbox
              >
            </div>
            <div class="second">
              <div
                v-for="(v, i) in item.son"
                :key="i"
                :class="i == 0 ? 'notop' : ''"
              >
                <el-checkbox
                  v-model="v.check"
                  :border="v.back"
                  :disabled="$route.query.type == 2"
                  @change="secondChange(item, index, i)"
                  >{{ v.name }}</el-checkbox
                >
              </div>
            </div>
            <div class="authority">
              <div
                v-for="(v, i) in item.son"
                :key="i"
                :class="i == 0 ? 'notop' : ''"
              >
                <el-checkbox
                  v-for="(val, event) in v.son"
                  :key="event"
                  :border="val.back"
                  :class="event == 0 ? 'notop' : ''"
                  v-model="val.check"
                  :disabled="$route.query.type == 2"
                  @change="authorityChange(item, index, v, i, val)"
                  >{{ val.name }}</el-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="role-info" v-if="$route.query.type == 2">
      <div>创建人：{{ roleInfo.creater_name }}</div>
      <div>创建时间：{{ roleInfo.create_time }}</div>
      <div>最近编辑人：{{ roleInfo.operator_name }}</div>
      <div>最近编辑时间：{{ roleInfo.update_time }}</div>
    </div>
    <div class="button-box">
      <el-button class="toback" @click="toBack()">返回</el-button
      ><el-button
        class="affirm"
        v-if="$route.query.type == 1"
        @click="addRole(true)"
        >确认新建</el-button
      ><el-button
        class="affirm"
        v-if="$route.query.type == 3"
        @click="addRole(false)"
        >保存</el-button
      >
    </div>
    <el-dialog
      title="切换参考角色提醒"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <span
        >切换参考角色后，当前角色将被赋予新参考角色下的权限，请谨慎操作。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="返回提醒"
      :visible.sync="dialogVisible2"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span
        >您已编辑部分角色信息，确定要放弃本次新建角色操作？点击确认返回角色列表页面，点击取消继续本次操作。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmBack()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power", "getMenuList"); //权限vuex公共库
export default {
  data() {
    return {
      optionsList: [],
      name: "",
      describe: "",
      old: {
        name: "",
        describe: "",
      },
      reference: "",
      allPowerList: [],
      checked: false,
      seachValue: [],
      seachOption: [],
      roleInfo: {},
      selected: true,
      dialogVisible: false,
      dialogVisible2: false,
      oldcheck: [],
    };
  },
  created() {
    this.toEditBtn();
    this.getconsult();
    if (this.$route.query.type != 1) {
      this.getgetroleInfo(this.$route.query.id);
    }
  },
  methods: {
    ...mapActions([
      "getallAuth",
      "getconsultRole",
      "getadminMenuIds",
      "postCreateRole",
      "getroleInfo",
      "postroleEdit",
    ]),
    ...commonIndex.mapActions([]),
    // 重新获取已有权限
    // 确认返回
    confirmBack() {
      this.$router.push("/RolaList");
    },
    blank() {
      this.name = this.name.replace(/\s+/g, "");
    },
    // 确定切换
    confirm() {
      this.selected = true;
      this.dialogVisible = false;
      this.station(this.reference, true);
    },
    // 角色权限列表
    async toEditBtn() {
      let data = await this.getallAuth();
      if (data.code == 200) {
        data.data.forEach((item, index) => {
          let obj = {};
          this.$set(item, "check", false);
          this.$set(item, "back", false);
          obj.label = item.name;
          obj.value = index;
          if (item.son) {
            obj.children = [];
            item.son.forEach((v, i) => {
              let obj1 = {};
              this.$set(v, "check", false);
              this.$set(v, "back", false);
              obj1.label = v.name;
              obj1.value = i;
              if (v.son) {
                obj1.children = [];
                v.son.forEach((val, i2) => {
                  this.$set(val, "check", false);
                  let obj2 = {};
                  obj2.label = val.name;
                  obj2.value = i2;
                  obj1.children.push(obj2);
                });
              }
              obj.children.push(obj1);
            });
          }
          this.seachOption.push(obj);
        });
        if (this.$route.query.type != 1) {
          // this.reference = this.$route.query.id * 1;
          this.station(this.$route.query.id * 1);
        }
        this.allPowerList = data.data;
        // this.isEdit = true;
        return;
      }
      this.$message.error("请联系后台人员");
    },
    // 获取当前账户可查看的参考角色
    async getconsult() {
      let data = await this.getconsultRole();
      this.optionsList = data.data;
    },
    // 获取已选中角色
    async station(val, event) {
      let num = this.allPowerList.some((item) => {
        return item.check;
      });
      if (num && !event && val != "") {
        this.dialogVisible = true;
        return false;
      }
      this.allPowerList.forEach((v2) => {
        v2.check = false;
        if (v2.son) {
          v2.son.forEach((v3) => {
            v3.check = false;
            if (v3.son) {
              v3.son.forEach((v4) => {
                v4.check = false;
              });
            }
          });
        }
      });
      let data = await this.getadminMenuIds({ role_id: val });
      this.selected = false;
      if (data.code != "200") {
        return;
      }
      this.oldcheck = data.data;
      data.data.forEach((v1) => {
        this.allPowerList.forEach((v2) => {
          if (v1 == v2.id) {
            v2.check = true;
          }
          if (v2.son) {
            v2.son.forEach((v3) => {
              if (v1 == v3.id) {
                v3.check = true;
              }
              if (v3.son) {
                v3.son.forEach((v4) => {
                  if (v1 == v4.id) {
                    v4.check = true;
                  }
                });
              }
            });
          }
        });
      });
      let is_super = false;
      this.optionsList.forEach((v) => {
        if (v.id == this.reference) {
          if (v.is_super == 1) {
            is_super = true;
          }
        }
      });
      if (is_super) {
        this.allPowerList.forEach((v2) => {
          v2.check = true;
          if (v2.son) {
            v2.son.forEach((v3) => {
              v3.check = true;
              if (v3.son) {
                v3.son.forEach((v4) => {
                  v4.check = true;
                });
              }
            });
          }
        });
        return;
      }
      if (this.$route.query.type == 2 && this.roleInfo.is_super != "1") {
        let num = this.allPowerList.filter((v) => {
          return v.check;
        });
        num.forEach((v) => {
          let num2 = v.son.filter((v1) => {
            return v1.check;
          });
          num2.forEach((v2) => {
            if (v2.son) {
              let num3 = v2.son.filter((v4) => {
                return v4.check;
              });
              v2.son = num3;
            }
          });
          v.son = num2;
        });
        this.allPowerList = num;
      }
      if (this.roleInfo.is_super == "1") {
        this.allPowerList.forEach((v2) => {
          v2.check = true;
          if (v2.son) {
            v2.son.forEach((v3) => {
              v3.check = true;
              if (v3.son) {
                v3.son.forEach((v4) => {
                  v4.check = true;
                });
              }
            });
          }
        });
      }
    },
    // 返回
    toBack() {
      let permissions_ids = [];
      this.allPowerList.forEach((v) => {
        if (v.check) {
          permissions_ids.push(v.id);
          if (v.son) {
            v.son.forEach((v1) => {
              if (v1.check) {
                permissions_ids.push(v1.id);
                if (v1.son) {
                  v1.son.forEach((v2) => {
                    if (v2.check) {
                      permissions_ids.push(v2.id);
                    }
                  });
                }
              }
            });
          }
        }
      });
      if (
        this.$route.query.type == 1 &&
        (this.name != "" || this.describe != "" || permissions_ids.length > 0)
      ) {
        this.dialogVisible2 = true;
        return;
      }
      let ischage =
        permissions_ids.length === this.oldcheck.length &&
        permissions_ids.sort().toString() === this.oldcheck.sort().toString();
      if (
        (this.$route.query.type == 3 && !ischage) ||
        this.name != this.old.name ||
        this.describe != this.old.describe
      ) {
        this.dialogVisible2 = true;
        return;
      }
      this.$router.push("/RolaList");
    },
    // 一级菜单改变
    firstChange(item, index) {
      if (!item.check) {
        item.son.forEach((v) => {
          v.check = false;
          if (v.son) {
            v.son.forEach((v1) => {
              v1.check = false;
            });
          }
        });
      }
    },
    // 二级菜单改变
    secondChange(item, index, i) {
      this.allPowerList[index].check = false;
      let num = item.son.filter((v) => {
        return v.check;
      });
      if (num.length > 0) {
        this.allPowerList[index].check = true;
      }
      if (
        this.allPowerList[index].son[i] &&
        !this.allPowerList[index].son[i].check &&
        this.allPowerList[index].son[i].son
      ) {
        this.allPowerList[index].son[i].son.forEach((val) => {
          val.check = false;
        });
      }
    },
    // 按钮权限
    authorityChange(item, index, v, i, value) {
      if (value.check) {
        this.allPowerList[index].son[i].check = false;
        let num = v.son.filter((val) => {
          return val.check;
        });
        if (num.length > 0) {
          this.allPowerList[index].son[i].check = true;
        }
        if (value.id == 154) {
          this.ganged(154, 153, value, index, i);
        }
        if (value.id == 160) {
          this.ganged(160, 158, value, index, i);
        }
        if (value.id == 151) {
          this.ganged(151, 150, value, index, i);
        }
        if (value.id == 135) {
          this.ganged(135, 134, value, index, i);
        }

        this.secondChange(item, index);
      }
    },
    // 按钮权限联动
    ganged(original, objective, value, index, i) {
      if (value.id == original) {
        this.allPowerList[index].son[i].son.forEach((v1) => {
          if (v1.id == objective) {
            v1.check = true;
          }
        });
      }
    },
    // 查询权限设置
    inquireBtn() {
      this.allPowerList.forEach((v) => {
        v.back = false;
        if (v.son) {
          v.son.forEach((v1) => {
            v1.back = false;
            if (v1.son) {
              v1.son.forEach((v2) => {
                v2.back = false;
              });
            }
          });
        }
      });
      if (this.seachValue.length == 1) {
        let index = this.seachValue[0];
        this.allPowerList[index].back = true;
      }
      if (this.seachValue.length == 2) {
        let index = this.seachValue[0];
        let i = this.seachValue[1];
        this.allPowerList[index].son[i].back = true;
      }
      if (this.seachValue.length == 3) {
        let index = this.seachValue[0];
        let i = this.seachValue[1];
        let i2 = this.seachValue[2];
        this.allPowerList[index].son[i].son[i2].back = true;
      }
    },
    // 获取角色信息
    async getgetroleInfo(val) {
      let res = await this.getroleInfo({ role_id: val });
      if (res.code == "200") {
        this.name = res.data.name;
        this.describe = res.data.describe;
        this.roleInfo = res.data;
        this.old.name = res.data.name;
        this.old.describe = res.data.describe;
        if (this.roleInfo.is_super == "1") {
          this.allPowerList.forEach((v2) => {
            v2.check = true;
            if (v2.son) {
              v2.son.forEach((v3) => {
                v3.check = true;
                if (v3.son) {
                  v3.son.forEach((v4) => {
                    v4.check = true;
                  });
                }
              });
            }
          });
        }
      }
    },
    // 确认新建与编辑
    async addRole(data) {
      if (this.name == "") {
        this.$message({
          message: "角色名称不能为空",
          type: "warning",
        });
        return;
      }
      let permissions_ids = [];
      this.allPowerList.forEach((v) => {
        if (v.check) {
          permissions_ids.push(v.id);
          if (v.son) {
            v.son.forEach((v1) => {
              if (v1.check) {
                permissions_ids.push(v1.id);
                if (v1.son) {
                  v1.son.forEach((v2) => {
                    if (v2.check) {
                      permissions_ids.push(v2.id);
                    }
                  });
                }
              }
            });
          }
        }
      });

      if (permissions_ids.length == 0) {
        this.$message({
          message: "请至少勾选一项菜单或操作权限",
          type: "warning",
        });
        return;
      }
      let params = {
        name: this.name,
        describe: this.describe,
        permissions_ids,
      };
      let res = {};
      if (data) {
        res = await this.postCreateRole(params);
      } else {
        params.role_id = this.$route.query.id;
        res = await this.postroleEdit(params);
      }
      if (res.code == "200" && this.$route.query.type == 1) {
        this.$message({
          message: `您已成功创建新的角色【${this.name}】`,
          type: "success",
        });
        this.$router.push("/RolaList");
      }
      if (res.code == "200" && this.$route.query.type == 3) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.$router.push("/RolaList");
      }
      if (res.code != "200") {
        this.$message({
          message: res.message,
          type: "warning",
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.role {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;
}
.input-box {
  display: flex;
  align-items: center;
  // width: 300px;
}
.sch {
  width: 75px;
  display: inline-block;
  color: #000;
  span {
    color: #f00 !important;
  }
}
.install {
  margin-bottom: 10px;
}
.permissionList {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0;
  font-size: 14px;
  .input-box {
    .el-button {
      margin-left: 20px;
      border: none;
      background-color: #06b7ae;
      color: #fff;
    }
  }
  .table-head {
    border-radius: 4px 4px 0px 0px;
    border: 1px solid #d4d7d9;
    display: flex;
    margin-top: 10px;
    height: 40px;
    background-color: #f2f3f5;
    line-height: 40px;
    box-sizing: border-box;
    color: #6d7687;
    font-size: 14px;
    border-bottom: none;
  }
  .table-body {
    border-radius: 0px 0px 4px 4px;
    border: 1px solid #d4d7d9;
    border-top: none;
    line-height: 40px;
    .table-row {
      display: flex;
      border-top: 1px solid #d4d7d9;
    }
  }
  .first,
  .second {
    width: 250px;
    border-right: 1px solid #d4d7d9;
    text-align: center;
  }
  .first {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    div {
      border-top: 1px solid #d4d7d9;
      box-sizing: border-box;
      height: 80px;
      line-height: 80px;
    }
    .notop {
      border-top: none;
    }
  }
  .authority {
    flex: 1;
    text-align: center;
    div {
      border-top: 1px solid #d4d7d9;
      // min-height: 40px;
      height: 80px;
      line-height: 40px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-left: 10px;
    }
    .notop {
      border-top: none;
    }
  }
}
.button-box {
  display: flex;
  justify-content: center;
  button {
    border: none;
    height: 32px;
    line-height: 32px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 14px;
  }
  .toback {
    color: #383b46;
    background-color: rgba($color: #2e3238, $alpha: 0.05);
  }
  .affirm {
    color: #fff;
    background-color: #06b7ae;
  }
}
.role-info {
  display: flex;
  font-size: 14px;
  color: #6d7687;
  justify-content: space-between;
  padding-top: 20px;
  border-top: #6d7687 1px dashed;
  margin-bottom: 20px;
}
::v-deep .el-input,
.el-select {
  width: 215px;
}
::v-deep .el-input--suffix {
  width: 215px;
}
::v-deep .el-checkbox__label {
  color: #000;
}
::v-deep .is-bordered {
  background: rgba($color: #000000, $alpha: 0.5);
  border: none;
  height: 38px;
  span {
    color: #fff;
  }
}
</style>
